import el from "element-ui/lib/locale/lang/en"
import fu from "fit2cloud-ui/src/locale/lang/en_US" // 加载fit2cloud的内容

const message = {
  commons: {
    message_box: {
      alert: "Warning",
      confirm: "Confirm",
      prompt: "Prompt"
    },
    dialog: {
      delete: "Delete"
    },
    confirm_message: {
      delete: "This operation cannot be undone, do you want to continue?",
      uncordon: "This operation will restore the node to a schedulable state. Do you want to continue?",
      enable_component_select: "No synchronization components have been selected. Do you want to synchronize all?",
      enable_component: "This operation will enable the cluster {0} component. Continue?",
      disable_component: "This operation will disable the cluster {0} component. Continue?",
      markAsRead: "Make sure to mark all as read？",
      operator: "Action object",
      delete_help: "This action cannot be undone, which permanently deletes the above action object?",
    },
    button: {
      login: "Login",
      ok: "Ok",
      save: "Save",
      delete: "Delete",
      import: "Import existed",
      import_exist: "导入已有",
      watch: "Watch",
      pause: "Pause",
      batch_import: "Batch Import",
      upgrade: "Upgrade",
      check: "Diagnostic",
      cancel: "Cancel",
      return: "Return",
      create: "Create",
      confirm: "Confirm",
      edit: "Edit",
      sync: "Sync",
      enable: "Enable",
      disable: "Disable",
      jump_to: "Go to",
      search: "Search",
      verify: "Verify",
      management: "Management",
      more: "more",
      back_to_form: "form",
      submit: "Submit",
      refresh: "Refresh",
      authorize: "Authorize",
      revoke_authorize: "Cancel Authorization",
      getBucket: "Get bucket",
      update: "Update",
      upload: "Upload",
      retry: "Retry",
      history: "History",
      error_msg: "Error",
      re_enable: "ReEnable",
      lock: "Enable/Disable",
      relation: "Associate",
      active: "Active",
      cordon: "Cordon",
      uncordon: "Resume to disperse",
      log: "Log",
      dashboard: "dashboard",
      more_info: "More",
      backup: "Backup",
      restore: "Restore",
      test_connection: "Test connection",
    },
    msg: {
      success: "{0} success",
      op_success: "Operate successfully",
      save_success: "Saved successfully",
      delete_success: "Deleted task has commit",
      restart_after_delete: "Operate successfully, Restart the node after the deletion succeeds",
      sync_success: "Synchronized successfully",
      create_success: "Created successfully",
      verify_success: "Verify successfully",
      import_success: "Imported successfully",
      enable_success: "Activated successfully",
      re_enable_success: "Re-enable successfully",
      disable_success: "Disabled successfully",
      update_success: "Update completed",
      upgrade_start_success: "The cluster upgrade has started, please wait...",
      metric_server_error: "The metrics-server service is unavailable. View the Metrics-Server Pod logs in the kube-system space to help locate the fault.",
    },
    validate: {
      limit: "Length from {0} to {1} characters",
      input: "Please enter {0}",
      input_error: "Please enter the correct content as prompted by the input box to confirm deletion!",
      cannot_be_empty: "The content cannot be empty!",
      name_not_compliant: "The name does not conform to the naming convention!",
      select: "Please choose",
      required_msg: "Entry cannot be empty",
      ip_error: "Please enter the correct IP address",
      password_help: "Valid password: 8-30 digits, English letters + numbers + special characters (optional)",
      number_limit: "Please enter the correct number",
      email: "E-mail format is incorrect",
      common_name_help: "Lowercase English, numbers and-",
      name_help: "Chinese, uppercase and lowercase English, numbers, - and .",
      ip_range_error: "IP range error",
      cidr_error: "Please enter the correct cidr!",
      subnet_error: "Subnet error",
      support_image_type: "Only JPG/PNG/JPEG files can be uploaded!",
    },
    search: {
      name: "",
      quickSearch: "Fast Search",
      select: "Please select {0}",
      time: "Time",
      time_start: "Starting Time",
      time_end: "End Time",
      time_range: "to",
      date_start: "Start Date",
      date_end: "End Date",
      synchronizing: "Synchronizing",
    },
    personal: {
      change_password: "Change Password",
      sync_password: "Sync Password",
      help_documentation: "Help Document",
      exit_system: "Exit System",
      new_password: "New Password",
      original_password: "Old Password",
      confirm_password: "Confirm Password",
      confirm_password1_info: "Inconsistent Passwords",
      about: "About",
      project_url: "Project Address",
      issue: "Feedback",
      talk: "Participate in the discussion",
      star: "Star",
      version: "Version",
      ko_introduction: "is an open source, lightweight Kubernetes distribution that focuses on helping companies plan, deploy, and operate production-level Kubernetes clusters.",
      setting: "Personal"
    },
    help: {
      help: "Help",
      api_docs: "API Document",
      business_support: "Business Support",
      ko_docs: "Official Document",
    },
    table: {
      action: "Actions",
      name: "Name",
      status: "Status",
      description: "Description",
      create_time: "Created",
      spend_time: "Spend time",
      type: "Type",
      username: "Username"
    },
    status: {
      RUNNING: "Running",
      SUCCESS: "Success",
      FAILED: "Failed",
      WAITING: "Waiting",
      running: "Running",
      initializing: "Initializing",
      creating: "Creating",
      success: "Success",
      failed: "Failed",
      not_ready: "Not ready",
      notready: "Not ready",
      error: "Error",
      active: "Active",
      passive: "Disable",
      waiting: "Waiting",
      upgrading: "Upgrading",
      terminating: "Destroying",
      synchronizing: "Synchronizing",
      normal: "Normal",
      failure: "Abnormal",
      lost: "Losted",
      disable_scheduling: "Disable scheduling",
      enable: "Enable",
      disable: "Disable",
      bind: "Is binding",
    },
    role: {
      super_admin: "Super admin",
      admin: "Admin",
      user: "User"
    },
    form: {
      number_validate: "Please enter the correct number",
      ip_range_error: "Ip range is invalid",
      yes: "yes",
      no: "No",
      file_upload_help: "Drag the file here, or click upload",
    }
  },
  login: {
    username: "Username",
    password: "Password",
    title: "Login KubeOperator",
    welcome: "Welcome back, please enter your username and password to log in",
    expires: "The authentication information has expired, please log in again",
    captcha: "Verification code",
    forget_password: "Forgot password",
    reset_password: "Reset Password",
    email: "Email",
    reset_message: "Password reset successfully! Please check your emails"
  },
  theme: {
    systemName: "System name",
    system_name_help: "Will be displayed on the Enterprise Edition user login page（eg: KubeOperator)",
    login_image: "Image of login page",
    login_image_help: "Will be displayed on the Enterprise Edition user login page（Suggest size: 480px*480px）",
    icon: "Website icon",
    icon_help: "Website icon（Suggest size: 16px*16px）",
    logo: "Logo(Without text)",
    logo_help: "The zoom menu is displayed in the upper left of the admin page（Suggest size: 35px*40px）",
    logo_with_text: "Logo(With text)",
    logo_with_text_help: "It will be displayed in the upper left of the admin page（Suggest size: 212p*40px）",
    logo_about: "Image of about page",
    logo_about_help: "Will be displayed in the Enterprise edition user about interface（Suggest size: 212p*40px）",
    re_upload: "Upload",
    reset: "Reset",
  },
  route: {
    project_management: "Project Management",
    cluster: "Cluster",
    host: "Host",
    system_setting: "System Settings",
    manifest: "Version Management",
    message_center: "Message Center",
    backup_account: "Backup Account",
    system_log: "System Log",
    complex_table: "Compound Form",
    user: "User Management",
    multi_cluster: "Multi-cluster Management",
    theme: "Interface setting",
    ldap: "LDAP",
  },
  cluster: {
    cluster: "Cluster",
    project: "Project",
    cluster_detail: "Cluster Details",
    version: "Version",
    scan_policy: "Scan Policy",
    node_size: "Nodes",
    creation: {
      step1: "Cluster Information",
      name: "Name",
      name_type: "Node naming rules",
      name_type_default_help: "The name is in the form of 'cluster-name'-master-1",
      name_type_ip_help: "It is named by the host IP address",
      name_type_host_help: "It is named by the host name",
      name_check: "Checking",
      name_help: "Maximum length: 50, start with lowercase letters, end with lowercase letters or numbers, support lowercase letters, numbers and-",
      name_invalid_err: "Invalid cluster name",
      provider: "Provider",
      provide_plan: "Plan",
      provide_bare_metal: "Bare metal",
      version: "Version",
      arch: "Architecture",
      repo_err: "Please complete the corresponding warehouse information in the system settings",
      yum_repo: "Yum warehouse",
      yum_repo_replace_help:
        "This operation will back up the original yum repo file of the K8S node server, and then generate and use only the yum repo of KubeOperator",
      yum_repo_coexist_help: "This operation will keep the original yum repo file of the K8S node server unchanged, while generating and using kubeoperator's yum repo",
      yum_repo_no_help: "This operation will keep using the original yum repo file of the K8S node server, and will not do any operation on the yum repo of the K8S node server",

      step2: "Cluster Setup",
      container_network: "Container Network",
      node_ip_num: "Max Pod IP",
      pod_cidr: "Pod CIDR",
      pod_cidr_help1: "Be unique with node. A cluster supports a maximum of ",
      pod_cidr_help2: " hosts，Each host can hold a maximum of ",
      pod_cidr_help3: " Pods",
      pod_cidr_help: "The network segment must be unique with node",
      service_cidr: "Service CIDR",
      service_cidr_help: "The value cannot be the same as node or Pod network segment CIDR",
      max_node_pod_num: "Maximum  of POD/nodes",
      max_cluster_service_num: "Maximum  of services/cluster",
      proxy_mode: "Proxy Mode",
      address: "Address",
      address_help: "This parameter is not mandatory. All local addresses are used by default",
      port_range: "Range",
      range_err: "Please enter the correct port range",
      dns_cache: "DNS Cache",
      kubernetes_audit: "Kubernetes Audit",
      enable: "Enable",
      disable: "Disable",
      network_help: "CIDR cannot overlap with the target IP segment, otherwise it will cause initialization failure",
      max_node_num_show: "Under the current container network configuration, the cluster has at most {0} nodes",

      step3: "Runtime Settings",
      runtime_type: "Container Runtime",
      docker_mirror_registry: "Registry Mirrors",
      docker_remote_api: "Remote API",
      docker_storage_dir: "Data Path",
      subnet: "Container Subnet",

      step4: "Container Network Settings",
      multi_network: "Multi Network",
      network_interface: "Interface",
      network_name: "Interface",
      network_cidr: "Cidr",
      network_cidr_help: "Multiple segments are separated by commas",
      network_interface_fannel_help: "Multiple network card environment needs to specify the name of the network card to be used, single network card environment is not required",
      network_interface_help: "Multiple network card environment need to specify the network card name or network segment information used by cluster nodes, single network card environment is not required",
      network_type: "Container Network",
      flannel_backend: "Network Mode",
      cilium_help: "Cilium officially recommends that the kernel version be at least 4.9.17",
      cilium_overlay_help: "Based on the encapsulated virtual network, all hosts are generated. Currently VXLAN and Geneve have been completed, but all encapsulation formats supported by Linux can be enabled.",
      cilium_overlay_help_more: "This pattern has minimal infrastructure and integration requirements. It works on almost any network infrastructure, the only requirement being an IP connection between hosts.",
      cilium_native_help: "Use the regular routing table of the Linux host. The network must be able to route the IP address of the application container. This mode is suitable for advanced users who need to understand the underlying network infrastructure.",
      native_routing: "Native Routing",
      tunnel_name: "Tunnel",
      cilium_native_help_more: "Suitable for (1. Native IPv6 network, 2. Use with cloud network router, 3. If you are already running a routing daemon)",
      flannel_backend_help_route_base: "Based on routing, not applicable to public cloud environment;",
      flannel_backend_help_route_advantage: "The advantage is that there is no packet and unpacking process, and the forwarding is completely based on the routing tables of the hosts at both ends.",
      flannel_backend_help_route_inferiority: "The disadvantage is that the host is required to be interoperable in the layer 2 network, and the expansion of the routing table will cause performance degradation。",
      flannel_backend_help_channel_base: "Based on the tunnel, it can work normally in any network environment;",
      flannel_backend_help_channel_advantage: "The advantage is that there are no special requirements for the physical network environment, as long as the host IP layer can be routed and communicated.",
      flannel_backend_help_channel_inferiority: "The disadvantage is that packaging and unpacking consumes CPU performance, and additional packaging leads to waste of bandwidth。",

      step5: "Component Settings",
      ingress_type: "ingress type",
      support_gpu: "Install GPU kit",
      etcd_data_dir: "Data Dir",
      etcd_snapshot_count: "Snapshot Count",
      etcd_snapshot_count_help: "Etcd Number of committed transactions that triggered snapshots to disk",
      etcd_compaction_retention: "Compaction Retention (小时)",
      etcd_compaction_retention_help: "Holding force for MVCC key-value storage, where 0 indicates turning off automatic compression",
      etcd_max_request: "Max Request (MiB)",
      etcd_max_request_help: "Etcd Raft Maximum number of bytes of a message",
      etcd_quota_backend: "Quota Backend (GB)",

      step6_of_bare_metal: "Node Information",
      node_help: "The host must first be authorized in project-Resource and in the running state in the host list",
      master_select_help: "The number of Master nodes must be 1 or 3",
      node_number_help: "In the current configuration, a maximum of {0} nodes can be deployed",
      cluster_high_availability: "High Availability",
      default: "Default",
      default_help: "The worker node deploys Haproxy, and all worker node components use the locally deployed Haproxy to access the Apiserve。",
      vip_help: "Haproxy + KeepAlive is deployed on the master node, and all worker components use VIP addresses to access the Apiserver.",
      port: "Port",

      step6_of_plan: "Plan",
      worker_num: "Number of Workers",
      master_schedule_type: "Master schedule",

      step7: "Configuration Preview",
      base_setting: "Basic Configuration"
    },
    upgrade: {
      current_version: "Current Version",
      upgrade_version: "Updated Version",
      upgrade_help: "Does not support K8s cross-large version upgrade or upgrade to K8s version that is not enabled, please confirm in version management。",
    },
    import: {
      auto_input: "Configure one-click default",
      import_cluster: "Cluster Import",
      credential_rules: "Please complete the host credential information!",
      port_rules: "Please complete the host port information!",
      is_ko_cluster: "Is cluster created by KubeOperator",
      load_ko_cluster_info: "Load cluster Infomation",
      provisioner_help: "Only NFS and external-ceph synchronization is supported. Other storage providers will support synchronization later.",
      provisioner_rule: "A storage provider (whose type is non-syncable) does not meet the synchronization conditions.",
      nfs_version_rule: "NFS version cannot be empty, please confirm.",
      ko_cluster_info: "Cluster Infomation",
      api_server_help: "For example: https://172.16.10.100:8443",
      router_help: "The IP address of any node with kube-proxy installed and accessible",
      batch_edit_port: "Batch modify ports",
      batch_edit_credential: "Batch modify credentials",
      other_info: "Other information",
      import_help: "Click to enable editing",
      import_help2: "For details, please refer to the official documentation",
      import_name: "The imported cluster name is the original cluster name: ",
      import_version_help: "If the imported cluster version is not supported by KubeOperator, the clustering tool uses the default configuration.",
      import_version_link: "Check version supported by KubeOperator",
    },
    delete: {
      delete_cluster: "Cluster Delete",
      delete_cluster_help: "This action cannot be undone. This will permanently delete the {0} cluster.",
      is_uninstall: "Uninstall the cluster",
      sure_uninstall: "The cluster uninstallation script will be executed.",
      is_force: "Mandatory delete",
      force_delete: "The error information in the cluster uninstallation script is ignored and the cluster metadata is deleted.",
      submit_delete_input: "Please enter {0} to confirm.",
    },
    health_check: {
      health_check: "health examination",
      level: "grade",
      message: "message",
      method: "method",
      result: "result",
      recover: "restore",
      CHECK_HOST_SSH_CONNECTION: "Check host SSH connection",
      CHECK_K8S_TOKEN: "Check kubernetes connection",
      CHECK_K8S_API: "Check kubernetes api connection",
      CHECK_K8S_NODE_STATUS: "Check kubernetes node status",
      CHECK_KUBE_ROUTER: "Check the availability of cluster proxy IP addresses",

      STATUS_SUCCESS: "Successful",
      STATUS_WARNING: "Warning",
      STATUS_FAILED: "Failed",
      STATUS_ERROR: "Error",
      STATUS_RECOVERD: "Recoverd",
      STATUS_SOLVED_MANUALLY: "To be solved",

      RECOVER_SYNC_NODE_STATUS: "Forcibly delete a node from the node list to uninstall related cluster services (manually)",
      RECOVER_SYNC_ROUTER_IP: "Update the cluster agent IP address",
      RECOVER_SYNC_TOKEN: "Get cluster token again",
      RECOVER_CONN_CONF: "Updated cluster connection information (manually)",
      RECOVER_HOST_CONN: "Checking the SSH service and network status of the host (manually)",
      RECOVER_API_CONN: "Check whether cluster services and cluster networks are available (manually)",
    },
    detail: {
      tag: {
        overview: "Overview",
        node: "Node",
        namespace: "Namespace",
        storage: "Storage",
        event: "Event",
        monitor: "Monitor",
        log: "Log",
        tool: "Tool",
        component: "Component",
        task: "task",
        backup: "Backup",
        security: "CIS Scan",
        grade: "Cluster Scoring",
      },
      overview: {
        source: "Source",
        base_info: "Basic Information",
        capacity_info: "Capacity Information",
        statistical_info: "Statistics",
        cpu: "CPU Usage",
        memory: "Memory Usage",
        docker: "Container Group",
        connect: "Execute WebKubeCtl command line",
        download_kube_config: "Download the KubeConfig file",
        open_in_new_windows: "Opens in a new window",
      },
      node: {
        increment: "Increment",
        operator_help: "The external import cluster does not support import",
        host: "Host",
        node_detail: "Node details",
        base_infomation: "Basic information",
        label: "Label",
        status: "Status",
        cordon: "Disperse",
        uncordon: "Restore",
        mode: "Mode",
        safe: "Safety",
        force: "Compulsory",
        safe_cordon_help: "If a node has an independent Pod or temporary data, it will be quarantined but will not be dispelled.",
        force_drain_help1: "delete permanently：",
        force_drain_help2: "1. Independent Pods and their data",
        force_drain_help3: "2. Pods configured with Empty Dir volume and their data",
        existing_cordoned: "The node cordoned already exists",
        existing_actived: "The node active already exists",
        is_master_node: "Master cannot be deleted",
        drain_success: "Forcibly evicting the node succeeded",
        node_expand: "Cluster expand",
        node_shrink: "Cluster shrink",
        node_expand_help: "In the current configuration, a maximum of {0} nodes can be added to a cluster",
      },
      namespace: {
        before_delete: "The deletion failed, the tool already exists in the namespace：",
      },
      component: {
        ensure_component_sync: "Are you sure to synchronize the following components?",
        operator_help: "Components of the same type cannot be enabled at the same time. To switch components of the same type, disable existing components.",
        METRICS_SERVER_HELPER: "Metrics Server is Kubernetes' scalable, efficient source of container resource Metrics for its built-in auto-scaling pipeline.",
        TRAEFIK_HELPER: "Traefik is a modern HTTP reverse proxy and load balancer that can easily deploy microservices.",
        NGINX_HELPER: "Ingress-nginx is an entry controller for Kubernetes that uses Nginx as a reverse proxy and load balancer.",
        GPU_HELPER: "The GPU Operator allows the administrator of the Kubernetes cluster to manage GPU nodes in the same way as CPU nodes in the cluster.",
        DNS_CACHE_HELPER: "NodeLocal DNSCache improves cluster DNS performance by running DNS cache proxies as DaemonSet on cluster nodes.",
        ISTIO_HELPER: "Istio is an open platform for connecting, managing, and securing microservices.",
        METALLB_HELPER: "MetalLB is a load-balancer implementation for bare metal Kubernetes clusters, using standard routing protocols.",
        NPD_HELPER: "Node-problem-detector is a daemon that monitors and reports on the health of a node.",
        cidrHelper: "cidr network segment: one in a row, for example, \n172.16.10.0/24\n172.16.20.0/24",
        metallb_param_error: "Make sure that at least one of the cidr and ip ranges is not empty!",
      },
      storage: {
        type: "Type",
        storage_class: "Storage Class",
        storage_clas_create: "Add Storage Class",
        provisioner_name: "Provider Name",
        provisioner: "Storage Provider",
        provisioner_short: "Provider",
        provisioner_create: "Add Storage Provider",
        provisioner_exist: "The storage provider already exists",
        provisioner_exist_help: "To add an existing storage provider to a cluster, import the storage provider synchronization of the cluster",
        provisioner_import_help: "The resource type does not match the storage provider type, please try again!",
        storage_class_exist: "This storage provider is already bound to the storage class and cannot be deleted！",
        disk_name: "Disk Name",
        disk_name_help: "Fill in the disk name, multiple disks are separated by commas, for example:'sdb,sdc'",
        is_block_enable: "Whether to enable block storage",
        cinder_version_help: "Currently only supports v3 version",
        vsphere_select_help: "Minimum requirements for VMware environment: 1. vCenter 6.7 Update 3, 2. ESXi v6.7.0",
        deploy_mode: "Deployment Type",
        multi_path_networking: "Multipath Networking",
        product: "Product",
        manage_url: "Management URL",
        user_name: "Username",
        password: "Password",
        storage_pool: "Storage Pool",
        logic_port: "Logical Port IP",
        business_ip: "iscsi Business IP",
        logic_port_help: "Multiple logical port IPs are separated by commas, for example:'test1,test2'",
        business_ip_help: "Multiple business IPs are separated by commas, for example: '10.1.1.1, 10.1.1.2'",
        vcenter_host: "vCenter Host",
        vcenter_port: "vCenter Port",
        vcenter_username: "vCenter Username",
        vcenter_password: "vCenter Password",
        ensure_provisioner_sync: "Are you sure to synchronize the following storage providers?",
        start_provisioner_sync: "Storage provider synchronization has started, please wait...",
        reclaim_policy: "Recycling Strategy",
        volume_binding_mode: "Volume binding mode",
        capacity: "Capacity",
        source: "Source",
        folder_help: "Clusters created in automatic mode default to kubeoperator",
        class: {
          clone_form_help: "Optional, the format is storage backend name. Original volume name",
          clone_speed_help: "Optional, default value 3, support 1~4, 4 is the fastest",
          storage_policy_type: "Storage Policy Type",
          storage_policy: "Storage Policy",
          build_in: "Built-in",
          custom: "Custom",
        },
      },
      monitor: {
        monitor_help: "The monitor service is not available, enable Prometheus in the cluster tool list",
        usage: "Usage",
        cpu_usage: "CPU Usage",
        cpu_avg_load: "Average CPU Load",
        memory_usage: "Memory Usage",
        minute: "min",
        minutes: "mins",
        hour: "hour",
        hours: "hours",
        disk_usage: "Disk Usage",
        disk_transfer: "Disk Throghput",
        inode_usage: "Inode Usage",
        net_transfer: "Network Bandwidth",
        read: "read",
        write: "write",
        in: "in",
        out: "out",
        step: "step",
        all_node: "all nodes",
        last_x_minutes: "last {0} mins",
        last_x_hours: "last {0} hours",
        last_x_days: "last {0} days",
      },
      event: {
        msg_info: "Message",
        msg_type: "Type",
        component: "Component",
        enable_npd: "Enable NPD",
        enable_npd_success: "NPD Successfully activated",
        disable_npd_success: "NPD Successfully disabled",
      },
      log: {
        log_help: "The log service is not available, enable Logging or Loki in the cluster tool list",
        time: "Time",
        msg_info: "Message Content",
        monitor_node: "Monitoring Node",
        label: "label",
        value: "Label Value",
        match_info: "Match Content",
        match_info_quick: "Search by matching content",
      },
      tool: {
        sync_tool: "Sync",
        sync_tool_help1: "Synchronization takes place in two steps",
        sync_tool_help2: "Synchronize cluster tool status",
        sync_tool_help3: "Sync chart warehouse",
        flex_ip: "Flex ip",
        enable_flex_ip_help: "The flex IP address of master-01 is used as the jump address of the tool",
        disable_flex_ip_help: "The IP address of master-01 will be used as the tool jump address",
        enable_title: "Enable Tool",
        disable_title: "Disable Tools",
        info_title: "Prompt Information",
        err_title: "Error Message",
        upgrade_title: "Version Upgrade",
        disable_show_msg: "Disabling this tool will result in data loss, whether to continue？",
        enable_storage: "Enable Storage",
        storage_class: "Storage Class",
        storage_size: "Size (Gi)",
        data_retention: "Data Retention (days)",
        search_index: "Prefix Index",
        search_index_help: "For example: logstash",
        replicas: "Replicas",
        max_replicas_num: "The maximum number of copies is",
        hip_memory: "Heap Memory (MB)",
        default_hip_memory: "The default value is 1G",
        password: "Password",
        password_re: "Confirm Password",
        log_err_msg: "logging and loki do not support simultaneous activation！",
        grafana_err_msg: "Please enable promethues as the default data source first!",
        grafana_password_help: "Inconsistent passwords",
      },
      istio: {
        err_title: "Error message",
        cpu_limit: "CPU limit (m)",
        memory_limit: "Memory limit (Mb)",
        cpu_request: "CPU request",
        memory_request: "Memory request (Mb)",
        trace: "Trace (%)",
        gateways_type: "Gateway type",
      },
      backup: {
        backup_strategy: "Backup Strategy",
        backup_interval: "Backup Interval (days)",
        retained_number: "Keep Copies",
        backup_account: "Backup Account",
        backup_account_rule: "Velero backup supports only OSS, S3, and MINIO",
        backup_account_help: "The backup account needs to be authorized to the cluster first",
        status: "Status",
        local_recover: "Local file recovery",
        backup_start: "Backup has started! Please check the log later",
        recover_start: "Recovery has begun! Please check the log later",
        local_recover_tips: "Please upload the original file, do not upload the compressed file",
        file: "File",
        mo_file_selected: "No file selected",
        file_select: "File Selection",
        backup_now: "Backup Now",
        backup_list: "Backup List",
        backup_location: "Backup Location",
        backup_recover: "Backup/Restore",
        backup_log: "Log",
        recover: "Restore",
        utc_zone: "Velero uses UTC time. Adjust the time according to the time zone",
        recover_success: "Start the recovery, please check the log later",
        CLUSTER_RESTORE: "Cluster Recovery",
        CLUSTER_BACKUP: "Cluster Backup",
        SUCCESS: "Success",
        Running: "Running",
        restore_message: "Make sure to use this backup file to restore？",
        FAILED: "Failed",
        detail: "Detail",
        backup_help: "If the number of retained copies is exceeded, the oldest backup file will be deleted",
        velero_backup: "Velero Backup",
        velero_restore: "Velero Restore",
        velero_describe: "Describe",
        velero_log: "Log",
        velero_detail: "Detail",
        velero_type: "Type",
        velero_namespace_include: "Include Namespace",
        velero_namespace_exclude: "Exclude Namespace",
        velero_resource_include: "Include resources",
        velero_resource_exclude: "Exclude resources",
        velero_backup_retention: "Backup retention time",
        velero_backup_setting: "Configuration",
        velero_backup_labels: "Backup Labels",
        velero_backup_Selector: "Selector",
        velero_include_cluster_resource: "Whether to include cluster resources",
        velero_type_normal: "Manual",
        velero_type_schedule: "Scheduled",
        velero_help: "If none of the following items are filled in, it will be a full backup",
        velero_list: "Backup List",
        velero_schedule: "Velero Schedule",
        velero_schedule_list: "Schedule List",
        velero_schedule_cycle:"Cycle",
        velero_schedule_help: "Create a backup every day at 3 am"
      },
      security: {
        start_time: "Starting Time",
        end_time: "End Time",
        cis_result: "Cis scan results",
        code: "Number",
        description: "Description",
        advise: "Suggest",
        start_cis: "Whether to start the CIS scan task？",
        create_task:"Create Task",
        auto: "Adaptive (adaptive scanning strategy according to current kubernetes version)"
      },
      f5: {
        big_ip_addr: "BIG-IP Address",
        big_ip_user_name: "BIG-IP Username",
        big_ip_password: "BIG-IP Password",
        big_ip_public: "BIG-IP Public IP",
      },
      grade: {
        danger: "Serious",
        warning: "Warning",
        pass: "Pass",
        total: "Total",
        health_checks: "health examination",
        images: "Image",
        networking: "Network",
        reliability: "Reliability",
        resources: "Resources",
        security: "Security",
        efficiency: "Efficiency"
      },
    },
  },
  task: {
    FAILED: "Task Failed",
    REDO: "Task Retry",
    name: "Task Name",
    task_detail: "Task Detail",
    task_id: "Task ID",
    message: "Error Message",
    condition_loading: "Waiting for execution, please wait...",
    condition_detail: "Status details",
    EnsureInitTaskStart: "Scheduling tasks",
    EnsurePrepareBaseSystemConfig: "Prepare the system environment",
    EnsurePrepareContainerRuntime: "Initialize the container engine",
    EnsurePrepareKubernetesComponent: "Prepare Kubernetes components",
    EnsurePrepareLoadBalancer: "Prepare the load balancer",
    EnsurePrepareCertificates: "Prepare the CA certificate",
    EnsureInitEtcd: "Initialize ETCD",
    EnsureInitMaster: "Initialize the control plane",
    EnsureInitWorker: "Initialize workload",
    EnsureInitNetwork: "Initialize the network",
    EnsureInitHelm: "Initialize Helm",
    EnsureInitMetricsServer: "Initialize the monitoring component",
    EnsureInitIngressController: "Initialize service exposure components",
    EnsurePostInit: "Configure the cluster",
    EnsureBackupETCD: "Backup cluster",
    EnsureUpgradeRuntime: "Upgrade the container engine",
    EnsureUpgradeETCD: "Upgrade ETCD",
    EnsureUpgradeKubernetes: "Upgrade Kubernetes",
    EnsureUpdateCertificates: "Renew certificate",
    EnsureUpgradeTaskStart: "Scheduling tasks",
    CLUSTER_CREATE: "Cluster Create",
    CLUSTER_DELEDE: "Cluster Delete",
    CLUSTER_UPGRADE: "Cluster Upgrade",
    CLUSTER_NODE_EXTEND: "Cluster Node Extend",
    CLUSTER_NODE_SHRINK: "Cluster Node Shrink",

    EnsureAddWorkerTaskStart: "Scheduling tasks",
    EnsureAddWorkerBaseSystemConfig: "Prepare the system environment",
    EnsureAddWorkerContainerRuntime: "Initialize the container engine",
    EnsureAddWorkerKubernetesComponent: "Prepare Kubernetes components",
    EnsureAddWorkerLoadBalancer: "Prepare the load balance",
    EnsureAddWorkerCertificates: "Prepare the CA certificate",
    EnsureAddWorkerWorker: "Initialize workload",
    EnsureAddWorkerNetwork: "Initialize the network",
    EnsureAddWorkerPost: "Configure the cluster",
    EnsureAddWorkerStorage: "Initialize the storage provisioner",
    DeleteNode: "Delete Node",

    CheckAPIStatus: "Check API status",
    CreateHost: "Create Host",
    cluster_task: "Cluster-level task",
    cluster_task_help: "Tasks that need to be performed independently, such as cluster creation, capacity expansion, and backup upgrade.",
    component_task: "Component-level task",
    component_task_help: "Allows multiple tasks to be executed simultaneously without affecting each other, such as components, storage provider enablement...",
  },
  host: {
    batch: "Batch Update",
    batch_port_operation: "Batch Port Modification",
    batch_credential_operation: "Batch Credential Modification",
    batch_list: "Modify the following host <{0}> information in batches",
    batch_length_error: "No host is selected for batch operation. Please select the host first.",
    detail: "Detail",
    host: "Host",
    public: "(Internet)",
    private: "(Private)",
    flex_ip: "Flex ip",
    enable_flex_ip: "Enable flex ip",
    disable_flex_ip: "Disable enable flex ip",
    memory: "Memory",
    mb: " MB",
    os: "System",
    architecture: "Arch",
    port: "Port",
    ensure_host_sync: "Are you sure to synchronize the following hosts？",
    start_host_sync: "Host synchronization has started, please wait...",
    template_download: "Template Download",
    not_support_format: "Unsupported file format",
    project_auth: "Project Authorization",
    cluster_auth: "Cluster Authorization",
    cluster_auth_help: "Can only be authorized to bare metal mode cluster",
    new_credential: "New Credential",
    type: "Type",
    credential: "Credential",
    credential_type: "Credential Type",
    credential_name: "Credential",
    exists_credential: "Exist Credentials",
    ip: "Address",
    gpu: "GPU",
    err_title: "Error Message",
    base_info: "Basic Information",
    disk_size: "Disk Capacity",
    config: "Config",
    cpu: "CPU",
    core: " core",
    size: "Size",
    gpu_info: "GPU Info",
    existing_authorized: "Authorized host exists",
    existing_unauthorized: "There are unauthorized hosts",
    project: "Project"
  },
  message: {
    message: "message",
    message_center: "Message Center",
    message_receiver: "Receiver",
    message_subscribe: "Subscribe",
    content: "Content",
    type: "Type",
    level: "Level",
    mark_as_read: "Mark",
    dingTalk_phone: "DingTalk Phone",
    dingTalk_phone_help: "Used for DingTalk to receive alarm messages",
    work_wechat_id: "Enterprise WeChat User ID",
    work_wechat_id_help: "Used for enterprise WeChat to receive alarm messages",
    work_wechat_url_help: "How to find userid?",
    email: "Email",
    message_type: "Type",
    message_in_station: "Station Message",
    mail: "Email",
    work_wechat: "Wechat",
    dingTalk: "DingTalk",
    message_system: "System message",
    message_cluster: "Cluster Message",
    receive_setting: "Receive Setting",
    user: "User",
    loading: "loading...",
    allRead: "All Read",
    no_letter: "暂无未读消息",
    title: {
      CLUSTER_EVENT_WARNING: "Cluster event alarm",
      CLUSTER_INSTALL: "Cluster installation",
      CLUSTER_IMPORT: "Cluster import",
      CLUSTER_REMOVE_WORKER: "Node Delete",
      CLUSTER_ADD_WORKER: "Node Add",
      CLUSTER_BACKUP: "Cluster Backup",
      CLUSTER_ENABLE_PROVISIONER: "Cluster enable provisioner",
      CLUSTER_DISABLE_PROVISIONER: "Cluster disable provisioner",
      CLUSTER_ENABLE_COMPONENT: "Cluster enable component",
      CLUSTER_DISABLE_COMPONENT: "Cluster disable component",
      CLUSTER_UN_INSTALL: "Cluster unInstall",
      CLUSTER_UPGRADE: "Cluster Upgrade",
      CLUSTER_DELETE: "Cluster Delete",
      CLUSTER_SCALE: "Cluster Scale",
      CLUSTER_RESTORE: "Cluster Restore ",
      LICENSE_EXPIRE: "License Expire",
      CLUSTER_OPERATOR: "Cluster Operator",
    },
    detail: {
      basicInformation: "Information",
      message_detail: "Detail",
      clusterName: "Cluster",
      host: "Host",
      name: "Name",
      type: "Type",
      kind: "Kind",
      component: "Component",
      detail: "Detail",
      namespace: "Namespace",
      cause: "Cause",
      time: "Time"
    },
    READ: "READ",
    UNREAD: "UNREAD"
  },
  user: {
    username: "Username",
    password: "Password",
    confirm_password: "Confirm Password",
    role: "Role",
    email: "Email",
    user: "User",
    type: "Source",
    ldap: "LDAP",
    local: "Local"
  },
  manifest: {
    title: "Version Management",
    detail: "Detail",
    enable: "Enable",
    enable_message: "version {0} successfully enabled！",
    disable_message: "version {0} successfully disabled！",
    component: "Component",
    version: "Version",
    see_documentation: "Documentation",
    manifest_help: "An offline package carries the latest version of Kubernetes by default. If you are in an offline environment, you will need to manually execute the script to generate the target version of Kubernetes offline package and push it to the Nexus repository."
  },
  system_log: {
    title: "SystemLog",
    operation: "Operation",
    operation_info: "Operation info",
    query_placeholder: "Search by name/operation/operation content"
  },
  automatic: {
    name: "Automatic Mode",
    cloud_provider: "Cloud Provider",
    datacenter: "DataCenter",
    basic: "Basic Information",
    detail: "Detail",
    kubepi_project_help: "This is the login credential of the project administrator. If a user is bound to the project and cluster, the user bound to the cluster administrator is preferred.",
    kubepi_cluster_help: "This is the cluster administrator login credentials.",
    kubepi_admin_help: "The password must be the same as the password configured in Dashboard user management.",
    region: {
      name: "Region",
      basic: "Basic Information",
      config: "Configuration Parameter",
      description: "Region: Similar to the concept of Region in the public cloud, such as Alibaba Cloud North China 1. For vSphere, the region corresponds to Datacenter.",
      vcenter_host: "Vcenter Host",
      vcenter_port: "Vcenter Port",
      vcenter_username: "Username",
      vcenter_password: "Password",
      openstack_identity: "identity",
      openstack_project: "Project ID",
      openstack_domain: "DomainName",
      fusionCompute_server: "FusionCompute Service Address",
      get_datacenter: "Get DataCenter",
      vcenter_api_help: "vCenter API Address",
      vcenter_user_help: "Username for accessing vCenter, for example administrator@vsphere.local",
      vcenter_password_help: "Password to access vCenter",
      openstack_keystone_help: "OpenStack keystone address",
      openstack_username_help: "Username for accessing OpenStack",
      openstack_password_help: "Password to access OpenStack",
      openstack_project_help: "OpenStack project ID",
      openstack_domain_help: "OpenStack Domain Name",
      fusionCompute_server_help: "FusionCompute API service address, for example: https://10.1.240.11:7443",
      fusionCompute_user_help: "User name for accessing FusionCompute, for example: admin",
      fusionCompute_password_help: "Password for accessing FusionCompute",
    },
    zone: {
      name: "Zone",
      description: "Zone: Similar to the concept of AZ in the public cloud, it can be understood as a specific computer room in a region, such as Beijing Zone 1 and Beijing Zone 2. For vSphere, the availability zone corresponds to the cluster, and it can also correspond to the resource pool under the cluster",
      ready: "Ready",
      uploadImageError: "Failed to upload image",
      initinitializingializing: "Initializing",
      config: "Configuration Parameter",
      compute: "Compute",
      cluster: "Cluster",
      resource_pool: "Resource Pool",
      datastore: "Datastore",
      value: "By Remaining Amount",
      usage: "By Usage",
      datastore_type: "Placement Method",
      template: "Template",
      template_type: "Template Type",
      default: "Default Template",
      customize: "Customize Template",
      template_config: "Config Template",
      network: "Network",
      network_adapter: "Network Adapter",
      security_group: "Security Group",
      ip_type: "IP Type",
      floating_ip: "Floating IP",
      subnet: "Subnet",
      storageType: "Storage Type",
      switch: "Switch",
      nfs_address: "NFS Server Address",
      nfs_port: "NFS Service Port",
      nfs_folder: "NFS Server Folder",
      nfs_username: "NFS Server Username",
      nfs_password: "NFS Server Password",
      old_datastores: "Selected Datastore",
      new_datastores: "New Datastore",
      initializing: "Initializing",
      port: "port",
      resource_type: "Resource Type",
      portgroup: "PortGroup"
    },
    plan: {
      name: "Plan",
      description: "Deployment plan (Plan): An abstract concept used in KubeOperator to describe in which area, in which availability zone, what machine specifications are used, and what type of cluster is deployed",
      deploy_template: "Deployment Model",
      SINGLE: "One master and multiple nodes",
      MULTIPLE: "Multi-master and multi-node",
      project_auth: "Authorization",
      master_model: "Master Specification",
      worker_model: "Worker Specification",
      config: "Specification Reference"
    },
    vm_config: {
      name: "Virtual Machine Configuration",
      cpu: "CPU (core)",
      memory: "RAM (MB)",
      name_validate_msg: "Support English and numbers",
      cpu_invalid: "CPU range 1～1000",
      mem_invalid: "Memory range 1～1000",
      disk: "Disk (GB)"
    },
    ip_pool: {
      name: "IP Pool",
      config: "IP Pool Config",
      subnet: "Subnet",
      ip_usage: "Usage",
      gateway: "Gateway",
      ip_start: "Start IP",
      ip_end: "End IP",
      dns1: "DNS1",
      dns2: "DNS2",
      description: "Description",
      address: "IP",
      status: "Status",
      ip_list: "IP List",
      ip_available: "Available",
      ip_lock: "Occupy",
      ip_used: "Using",
      ip_reachable: "Reachable",
      subnet_help: "172.1.10.0/24",
      ip_start_help: "172.1.10.1",
      ip_end_help: "172.1.10.10",
      gateway_help: "172.1.10.254",
      dns1_help: "8.8.8.8",
      dns2_help: "114.114.114.114",
    },
    template_config: {
      name: "Template Configuration",
      qcow2_path: "qcow2 file location",
      vmdk_path: "vmdk file location",
      ovf_path: "ovf file location",
      template_name: "Template name"
    }
  },
  project: {
    project: "Project",
    member: "Member Management",
    resource: "Resource Management",
    CLUSTER_MANAGER: "Cluster Manager",
    PROJECT_MANAGER: "Project Manager",
    description: "Description",
    add_project_manager: "Add Project Manager",
    add_project_resource: "Add Resource",
    add_cluster_manager: "Add Cluster Manager",
    key_words: "Please type in your name",
    list: "Project List",
    header_description: "The left side is the project-cluster tree, and the right side is the member and resource management (host and backup accounts need to be authorized to the target cluster for capacity expansion, backup and other operations)",
  },
  credential: {
    name: "Credential Name",
    credential: "Credential",
    username: "Username",
    password: "Password",
    privateKey: "Key",
    type: "Type"
  },
  backup_account: {
    name: "Backup Account",
    select_placeholder: "Select",
    table: {
      bucket: "Bucket",
      endpoint: "Endpoint",
      region: "Region",
      accountName: "AccountName",
      accountKey: "AccountKey",
      address: "Address",
      port: "Port",
      username: "Username",
      password: "Password",
      path: "Path",
    }
  },
  setting: {
    registry: "Registry",
    tips: "The default repo is nexus, and the management port is 8081",
    nexus_passwd: "This is to synchronize the Nexus repository login credentials to KubeOperator",
    address: "Address",
    username: "Username",
    password: "Password",
    sync_nexus_password: "Sync Nexus password",
    kubepi_conn_successful: "Kubepi connectivity check successful!",
    conn_successful: "Repo connectivity check successful!",
    kubepi_password_help: "Admin login password. The default value is kubepi",
    password_help: "Admin login password. The default value is admin123",
    ntp: "NTP",
    email: "Email",
    license: "License",
    apply_licensee: "Apply Licensee",
    message: "Message",
    credential: "Credential",
    ntpServer: "NTP Server",
    table: {
      registry: {
        protocol: "Protocol",
        hostname: "Address",
        arch: "CPU Architecture",
        default_password: "Default Password",
        registry_address: "Registry link",
        hostname_help: "The default is the IP of the server where KubeOperator is deployed. This IP will be used to access the nexus registry",
        repo_port_help: "Web access port of Nexus, also used in Yum/APT and wget download",
        repo_registry_port_help: "Use the Docker pull command to download the port used to download the image from the Nexus warehouse",
        repo_registry_hosted_port_help: "Use the Docker push command to upload the image to the port used by the Nexus warehouse",
        registry_and_repo_err: "Repo and registryHosted warehouse port conflict, please modify and resubmit",
        registry_and_registry_hosted_err: "Registry and registryHosted warehouse port conflicts, please modify and resubmit",
        repo_and_registry_hosted_err: "Registry and Repo warehouse port conflicts, please modify and resubmit",
      },
      mail: {
        smtp: "SMTP Address",
        port: "Port",
        username: "Username",
        password: "Password",
        testUser: "Test User",
        status: "Status"
      },
      ldap: {
        address: "Address",
        port: "Port",
        username: "Username",
        password: "Password",
        filterDN: "User Filtering DN",
        filterRule: "User Filtering Rules",
        status: "Status",
        ldapHelper: "Note: Users without mailboxes will not be synchronized, and those with the same login name as local users will not be synchronized!",
        ldap_mapping: "User Attribute Mapping",
        ldap_mapping_helper: "User attribute mapping represents how to map user attributes in LDAP to KubeOperator users, name, email are the attributes required by KubeOperator users",
        sync_success: "Start sync, please check result later",
        ldap_test: "Test connection",
        test_result: "Connection succeeded, matching {count} users",
        test_login: "Test Login",
        login_success: "Test login success",
        login_failed: "Login failed",
        import_user: "Import User",
        import_enable: "Whether it can be imported",
        import_user_success: "Import successful",
        import_user_failed: "Import failed user {user}",
        ldap_remake: "Reset",
        time_limit: "Connection timeout",
        size_limit: "Number of search pages",
        ldap_tls: "tls",
        ldap_helper: "Note: Users who cannot get the Name mapping attribute will not be matched",
      },
      license: {
        title: "Import license",
        corporation: "Company",
        count: "Count",
        expiration: "Expire Date",
        edition: "Version",
        status: "Status",
        valid: "Valid",
        invalid: "InValid",
        expired: "Be Expired",
        licenseFile: "License",
        communityEdition: "Community Edition"
      },
      message: {
        wechat: "Enterprise WeChat",
        dingTalk: "DingTalk",
        testUser: "Test User",
        status: "Status",
        webhookAddress: "Webhook Address",
        dingTalkConcept: "DingTalk Robot Message Sending Settings",
        dingTalkLimit: "DingTalk message frequency limit",
        wechatConcept: "Basic Concepts of Enterprise WeChat",
        wechatLimit: "Enterprise WeChat message frequency limit",
        wechatSetting: "Enterprise WeChat Settings",
        dingTalkSetting: "DingTalk Settings"
      }
    },
    option: {
      addRegistry: "Add Registry",
      editRegistry: "Edit Registry",
      addCredential: "Add SSH Credentials",
      editCredential: "Add SSH Credential",
      addLicense: "Add License"
    },
    helpInfo: {
      inputPassword: "Please enter the password",
      messageInfo: "Note: As each company has different message restrictions, KubeOperator does not make active restrictions. Please use it according to your own circumstances."
    }
  },
  multi_cluster: {
    multi_cluster: "Multi-cluster",
    repository_setting: "Registry Settings",
    basic_setting: "Basic Settings",
    senior_setting: "Advanced Settings",
    port_setting: "Port Settings",
    address: "Address",
    branch: "Branch",
    auth: "Certification",
    username: "Username",
    password: "Password",
    sync_interval: "Synchronization Interval (minutes)",
    pull_timeout: "Pull Timeout (minutes)",
    edit: "Edit",
    cluster_list: "Cluster List",
    selected_clusters: "Choose a Cluster",
    relation_management: "Association Management",
    log: "Log"
  }
}

export default {
  ...el,
  ...fu,
  ...message
};

