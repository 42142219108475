import el from "element-ui/lib/locale/lang/zh-TW";

const message = {
  // TODO
}

export default {
  ...el,
  ...message
};
