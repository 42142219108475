var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('el-row',[_c('el-tabs',{attrs:{"tab-position":"left"},on:{"tab-click":function($event){return _vm.changeTab()}},model:{value:(_vm.resourceType),callback:function ($$v) {_vm.resourceType=$$v},expression:"resourceType"}},[_c('el-tab-pane',{attrs:{"label":_vm.$t('host.host'),"name":"HOST"}},[_c('complex-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],key:_vm.key,attrs:{"data":_vm.data,"pagination-config":_vm.paginationConfig,"selects":_vm.selects},on:{"update:selects":function($event){_vm.selects=$event},"search":_vm.getProjectResourceList},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('el-button-group',[_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(_vm.role),expression:"role"}],attrs:{"size":"small"},on:{"click":function($event){return _vm.create()}}},[_vm._v(" "+_vm._s(_vm.$t("commons.button.create"))+" ")]),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(_vm.role),expression:"role"}],attrs:{"size":"small","disabled":_vm.selects.length === 0},on:{"click":function($event){return _vm.openDelete()}}},[_vm._v(" "+_vm._s(_vm.$t("commons.button.delete"))+" ")])],1)]},proxy:true}])},[_c('el-table-column',{attrs:{"type":"selection","fix":""}}),_c('el-table-column',{attrs:{"sortable":"","prop":"name","label":_vm.$t('commons.table.name'),"min-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.name))]}}])}),_c('el-table-column',{attrs:{"sortable":"","prop":"ip","label":_vm.$t('host.ip'),"min-width":"100px"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.ip))]}}])}),_c('el-table-column',{attrs:{"sortable":"","prop":"cpuCore","label":_vm.$t('automatic.vm_config.cpu'),"min-width":"50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.cpuCore))]}}])}),_c('el-table-column',{attrs:{"sortable":"","prop":"memory","label":_vm.$t('automatic.vm_config.memory'),"min-width":"50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.memory))]}}])}),_c('el-table-column',{attrs:{"sortable":"","prop":"gpuNum","label":_vm.$t('host.gpu'),"min-width":"50"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.gpuNum))]}}])}),_c('el-table-column',{attrs:{"sortable":"","prop":"os","label":_vm.$t('host.os')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.os)+" "+_vm._s(row.osVersion))]}}])})],1)],1),(_vm.authObj)?_c('el-tab-pane',{attrs:{"label":_vm.$t('automatic.plan.name'),"name":"PLAN"}},[_c('complex-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],key:_vm.key,attrs:{"data":_vm.data,"pagination-config":_vm.paginationConfig,"selects":_vm.selects},on:{"update:selects":function($event){_vm.selects=$event},"search":_vm.getProjectResourceList},scopedSlots:_vm._u([(_vm.authObj.type !== 'CLUSTER')?{key:"header",fn:function(){return [_c('el-button-group',[_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(_vm.role),expression:"role"}],attrs:{"size":"small"},on:{"click":function($event){return _vm.create()}}},[_vm._v(" "+_vm._s(_vm.$t("commons.button.create"))+" ")]),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(_vm.role),expression:"role"}],attrs:{"size":"small","disabled":_vm.selects.length === 0},on:{"click":function($event){return _vm.openDelete()}}},[_vm._v(" "+_vm._s(_vm.$t("commons.button.delete"))+" ")])],1)]},proxy:true}:null],null,true)},[_c('el-table-column',{attrs:{"type":"selection","fix":""}}),_c('el-table-column',{attrs:{"sortable":"","prop":"name","label":_vm.$t('commons.table.name'),"mix-width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.name))]}}],null,false,1151939480)}),_c('el-table-column',{attrs:{"label":_vm.$t('automatic.plan.deploy_template'),"mix-width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(" "+_vm._s(_vm.$t("automatic.plan." + row.deployTemplate))+" ")]}}],null,false,1361706384)}),_c('el-table-column',{attrs:{"sortable":"","prop":"createdAt","label":_vm.$t('commons.table.create_time'),"mix-width":"60"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm._f("datetimeFormat")(row.createdAt)))]}}],null,false,2490043697)})],1)],1):_vm._e(),_c('el-tab-pane',{attrs:{"label":_vm.$t('backup_account.name'),"name":"BACKUP_ACCOUNT"}},[_c('complex-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],key:_vm.key,attrs:{"data":_vm.data,"pagination-config":_vm.paginationConfig,"selects":_vm.selects},on:{"update:selects":function($event){_vm.selects=$event},"search":_vm.getProjectResourceList},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('el-button-group',[_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(_vm.role),expression:"role"}],attrs:{"size":"small"},on:{"click":function($event){return _vm.create()}}},[_vm._v(" "+_vm._s(_vm.$t("commons.button.create"))+" ")]),_c('el-button',{directives:[{name:"permission",rawName:"v-permission",value:(_vm.role),expression:"role"}],attrs:{"size":"small","disabled":_vm.selects.length === 0},on:{"click":function($event){return _vm.openDelete()}}},[_vm._v(" "+_vm._s(_vm.$t("commons.button.delete"))+" ")])],1)]},proxy:true}])},[_c('el-table-column',{attrs:{"type":"selection","fix":""}}),_c('el-table-column',{attrs:{"sortable":"","prop":"name","label":_vm.$t('commons.table.name'),"mix-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.name))]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('backup_account.table.bucket'),"mix-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(row.bucket))]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('commons.table.type'),"mix-width":"70"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.type === 'OSS')?_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#iconoss"}})]):_vm._e(),(row.type === 'AZURE')?_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#iconAzure"}})]):_vm._e(),(row.type === 'SFTP')?_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#iconSFTP"}})]):_vm._e(),(row.type === 'S3')?_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#icons3"}})]):_vm._e(),(row.type === 'MINIO')?_c('svg',{staticClass:"icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"xlink:href":"#iconminio"}})]):_vm._e(),_vm._v(" "),_c('span',[_vm._v(_vm._s(row.type))])]}}])}),_c('el-table-column',{attrs:{"label":_vm.$t('commons.table.status'),"mix-width":"40"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [(row.status === 'VALID')?_c('div',[_c('span',{staticClass:"iconfont iconduihao",staticStyle:{"color":"#32B350"}}),_vm._v(" "+_vm._s(_vm.$t("commons.status.normal"))+" ")]):_c('div',[_c('span',{staticClass:"iconfont iconerror",staticStyle:{"color":"#FA4147"}}),_vm._v(" "+_vm._s(_vm.$t("commons.status.failure"))+" ")])]}}])}),_c('el-table-column',{attrs:{"sortable":"","prop":"createdAt","label":_vm.$t('commons.table.create_time'),"mix-width":"100"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_vm._v(_vm._s(_vm._f("datetimeFormat")(row.createdAt)))]}}])})],1)],1)],1),_c('el-dialog',{attrs:{"visible":_vm.openCreatePage,"title":_vm.$t('project.add_project_resource'),"width":"30%"},on:{"close":_vm.cancel},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('span',{staticClass:"dialog-footer"},[_c('el-button',{on:{"click":_vm.cancel}},[_vm._v(_vm._s(_vm.$t("commons.button.cancel")))]),_c('el-button',{directives:[{name:"preventReClick",rawName:"v-preventReClick"}],attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v(_vm._s(_vm.$t("commons.button.submit")))])],1)]},proxy:true}])},[_c('el-select',{staticStyle:{"width":"100%"},attrs:{"size":"medium","multiple":"","filterable":""},model:{value:(_vm.form.names),callback:function ($$v) {_vm.$set(_vm.form, "names", $$v)},expression:"form.names"}},_vm._l((_vm.resources),function(item,index){return _c('el-option',{key:index,attrs:{"value":item.name}},[_c('span',{staticStyle:{"float":"left"}},[_vm._v(_vm._s(item.name))]),(_vm.resourceType==='HOST')?_c('span',{staticStyle:{"color":"#8492a6","font-size":"13px"}},[_vm._v(" "+_vm._s("\u00a0\u00a0\u00a0" + item.ip)+" ")]):_vm._e(),(_vm.resourceType==='PLAN')?_c('span',{staticStyle:{"color":"#8492a6","font-size":"13px"}},[_vm._v(" "+_vm._s("\u00a0\u00a0\u00a0" + _vm.$t("automatic.plan." + item.deployTemplate))+" ")]):_vm._e(),(_vm.resourceType==='BACKUP_ACCOUNT')?_c('span',{staticStyle:{"color":"#8492a6","font-size":"13px"}},[_vm._v(_vm._s("\u00a0\u00a0\u00a0" + item.bucket))]):_vm._e()])}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }